export function calculateDistanceInKm(originLatitude1, originLongitude1, destinationLatitude2, destinationLongitude2) {
    const RADIUS_OF_EARTH = 6371; // Radius of the earth in km
    const dLat = degreeToRadian(destinationLatitude2 - originLatitude1);  // degreeToRadian below
    const dLon = degreeToRadian(destinationLongitude2 - originLongitude1);
    var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(degreeToRadian(originLatitude1)) * Math.cos(degreeToRadian(destinationLatitude2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);

    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var distanceInKms = RADIUS_OF_EARTH * c; // Distance in km
    return distanceInKms;
}

function degreeToRadian(deg) {
    return deg * (Math.PI / 180)
}

export function convertKMToMiles(distanceInKms = 0) {
    return (distanceInKms * 0.621371);
}