import { createApp } from 'vue';
import App from './App.vue';
import store from "./store";
import router from './router';
import loading from "./helpers/loading";

createApp(App)
    //provide the loading function on app level so we can use it by injecting in component
    .provide("loading", loading)
    .use(store)
    .use(router)    
    .mount('#app');
