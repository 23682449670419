<template>
  <section class="section">
      <footer class="app-footer">
        <div class="content has-text-centered">
          <p>
            <span>
              <strong>Pinioneer</strong> by <router-link to="/about" class="">Jatinder, Eamon, Murray, and Michael</router-link>
            </span>
          </p>
          <div> Copyright &copy; {{ year }}</div>
        </div>
      </footer>
  </section>
</template>

<script>
 
import { computed } from 'vue'

export default {
  name: 'app-footer',
  setup () {
    const year = computed(() => new Date().getFullYear())
     return { year }
  }
};
</script>
 
 