<template>
  <section class="hero is-primary is-bold">
    <!-- Hero header: will stick at the top -->
    <nav class="navbar">
      <div class="navbar-brand">
        <router-link class="navbar-item" to="/"> Pinioneer</router-link>
        <!--
    Using the v-on: directive to listen for the click event and toggle the data property showNav. Also, using the v-bind: directive to reactively update the class attribute 'is-active' based on the showNav property.
    -->
        <div
          class="navbar-burger"
          @click="showNav = !showNav"
          :class="{ 'is-active': showNav }"
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <!--
    Using the v-bind: directive to reactively update the class attribute 'is-active' based on the showNav property.
    -->
      <div class="navbar-menu" :class="{ 'is-active': showNav }">
        <div class="navbar-end">
          <router-link to="/home" class="navbar-item">Home</router-link>
          <router-link class="navbar-item" to="/about"> About </router-link>
          <router-link class="navbar-item" to="/blog"> Blog </router-link>
        </div>
      </div>
    </nav>
  </section>
</template>

<script>
export default {
  data() {
    return {
      showNav: true,
    };
  },
};
</script>

<style lang="scss">
$primary: #772b90;

.app-navbar {
  position: fixed;
  min-width: 100%;
  z-index: 1024;
  background-color: white;

  .nav {
    margin: 0 auto;
    max-width: 90rem;
    height: 5rem;
  }
  // @include mobile() {
  //  height: 2rem;
  // }
}

.nav-right {
  text-transform: Capitalize;
  align-items: stretch;
  align-items: stretch;
  flex: 1;
  justify-content: flex-end;
  overflow: hidden;
  overflow-x: auto;
  white-space: nowrap;
}

.hero-brand {
  .tour-logo {
    max-height: 3rem;
  }

  .tour-title {
    font-size: 1.75rem;

    .vue {
      margin-left: 10px;
    }

    .bulma {
      margin-left: 5px;
    }
  }
}

.tour-drop-down {
  cursor: pointer;

  .tour-drop-down-list {
    box-sizing: border-box;
    position: absolute;
    top: 80%;
    right: 0.75rem;
    max-width: 7rem;
    background-color: white;
    padding: 0.75rem 0;
    border: 1px solid #ddd;
    border-bottom-color: #ccc;
    text-align: left;
    border-radius: 4px;
    white-space: nowrap;

    li {
      line-height: 1.8em;
      padding: 0.25rem 1.5rem;
      margin: 0;
      display: block;

      a:hover {
        color: $primary;
      }
    }
  }
}

.hvr-underline-from-center {
  display: flex;

  &:before {
    background: $primary;
  }
}
</style>