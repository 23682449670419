<template>
  <div class="flex has-text-centered mt-5 pt-5">
    <div class="notification">
      <div class="title">Let's get some help!</div>
      <form class="zipcode-form" @submit.prevent="searchNearByNIC">
        <div class="field">
          <div class="control">
            <input
              id="zipcode"
              type="text"
              name="zipcode"
              class="input is-size-5"
              size="5"
              placeholder="Enter Your ZipCode"
              required
              v-model="zipcode"
              @keyup="autoFillZipcodes"
              autocomplete="postal-code"
              list="autofill_zipcodes"
            />
            <datalist id="autofill_zipcodes">
              <option v-for="zip in autofill_zipcodes" :key="zip.zipcode">
                {{ zip.zipcode }}
              </option>
            </datalist>
          </div>
        </div>
        <button class="button is-primary is-large">Submit</button>
      </form>
      <div class="mt-5">
        <p>Zipcode</p>
        <p class="has-text-weight-bold">{{ zipcode }}</p>
      </div>
    </div>
    <div class="card">
      <header class="card-header">
        <p class="card-header-title">Top 3 Nearest NIH Clinics</p>
      </header>
      <div class="card-content">
        <div class="content">
          <div class="list">
            <ul class="m-0">
              <div
                v-for="clinic in filtered_clinics"
                :key="clinic.id"
                class="clinic"
              >
                <div class="list-item">
                  <li>
                    <router-link
                      :to="`/detailsview?nic=${clinic.id}&origin-zipcode=${zipcode}&origin-longitude=${selectedZipcode.longitude}&origin-latitude=${selectedZipcode.latitude}`"
                      class="has-text-weight-bold"
                    >
                      {{ clinic.name }}
                    </router-link>
                    <div>{{ clinic.address1 }} {{ clinic.address1 }}</div>
                    <div>
                      {{ clinic.city }}, {{ clinic.state }},
                      {{ clinic.zipcode }},
                    </div>
                    <div>{{ clinic.status }}</div>
                    <span class="has-text-info">({{ clinic.distance }})</span>
                    <div class="link">
                      <router-link
                        :to="`/detailsview?origin=${zipcode}&destination=${clinic.zipcode}&nic=${clinic.id}`"
                        >Read More...</router-link
                      >
                    </div>
                  </li>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { isZipcode } from "@/helpers/utils/string";
import {
  calculateDistanceInKm,
  convertKMToMiles,
} from "@/helpers/utils/geolocation";

export default {
  name: "ZipCode-Search-View",
  inject: ["loading"],
  data() {
    return {
      selectedZipcode: {
        longitude:0,
        latitude:0
      },
      zipcode: "",
      autofill_zipcodes: [],
      filtered_clinics: [],
      all_clinics: [],
      zipcodes: [],
    };
  },

  mounted() {
    this.fetchClinics();
    this.fetchZipcodes();
  },

  watch: {
    "$store.getters.getClinics": function (newVal) {
      this.all_clinics = newVal;
    },
    "$store.getters.getFilteredClinics": function (newVal) {
      this.filtered_clinics = newVal;
    },
  },

  methods: {
    /**
     * search near by top 3 NIC
     */
    searchNearByNIC() {
      if (isZipcode(this.zipcode)) {
        this.loading(true);
        this.searchNearestClinic();
      }
    },

    /**
     * fetch all the clinics
     */
    fetchClinics() {
      this.$store.dispatch("fetchClinics");
    },

    /**
     * fetch zipcodes
     */
    fetchZipcodes() {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/zipcodes" +
            process.env.VUE_APP_API_URL_SUFFIX
        )
        .then((res) => {
          if (res.status === 200) {
            this.zipcodes = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    /**
     * show zipcode suggestions
     */
    autoFillZipcodes(e) {
      const val = e.target.value;
      if (val && val.length > 1) {
        this.autofill_zipcodes = [...this.zipcodes].filter((el) =>
          el.zipcode.startsWith(val)
        );
      }
    },

    /**
     * search nearest clinic based on given zipcode
     */
    searchNearestClinic() {
      //1. find the user entered zipcode in our zipcodes list for geo coordinates
      const zipCodeFound = this.zipcodes.find(
        (el) => el.zipcode == this.zipcode
      );

      if (zipCodeFound) {
        this.selectedZipcode = zipCodeFound;
        //2. calculate the distance between each clinics zip code and user entered zip code using cordinates
        this.all_clinics.forEach((clinic) => {
          const distanceInKm = calculateDistanceInKm(
            zipCodeFound.latitude,
            zipCodeFound.longitude,
            clinic.latitude,
            clinic.longitude
          );
          clinic.distance = convertKMToMiles(distanceInKm).toFixed(2);
        });

        //3. sort the zip code based on distance
        this.all_clinics.sort((a, b) => a.distance - b.distance);
        this.filtered_clinics = [];
        this.filtered_clinics = this.all_clinics.slice(0, 3);

        //4. show the list of top 3 nearest clinics
        this.$store.commit("setFilteredClinics", this.filtered_clinics);

        this.loading(false);
      }
    },
  },
};
</script>

<style>
/* center the zipcode form */

.zipcode-form {
  align-content: center;
}
</style>
      