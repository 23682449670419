<template>
  <section class="section">
    <div class="container">
      <div class="card">
        <header class="card-header">
          <p class="card-header-title">
            {{ pagetitle }}
          </p>
        </header>
        <div class="card-content">
          <div class="content">
            <div class="list">
              <ul>
                <div v-for="flight in flights" :key="flight.id" class="flight">
                  <div class="list-item">
                    <li>
                      <a
                        :href="flight.weburl"
                        target="_blank"
                        class="has-text-weight-bold"
                        rel="noopener noreferrer"
                      >
                        {{ flight.airline }} ||
                      </a>
                      <span>
                        {{ flight.duration }} || {{ flight.connections }} || ${{
                          flight.price
                        }}
                      </span>
                    </li>
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Flights-View",
  data() {
    return {
      flights: [],
      pagetitle: "Fight Info",
    };
  },
  mounted() {
    fetch(
      process.env.VUE_APP_API_URL +
        "/flightinfo" +
        process.env.VUE_APP_API_URL_SUFFIX
    )
      .then((res) => res.json())
      .then((data) => (this.flights = data))
      .catch((err) => console.log(err.message));
  },
};
</script>
<style>
/* left align the list */
div.panel-content {
  text-align: left;
}
div.content ul {
  display: inline-flex;
  flex-direction: column;
}

div.content ul li {
  text-align: left;
}
</style>
 