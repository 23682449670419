<template>
  <div class="app">
    <div class="loading" v-show="isLoading">
      <div class="spinner">
        <span class="loading-btn button is-loading">Loading</span>
      </div>
    </div>
    <header>
      <NavBar></NavBar>
    </header>
    <main class="main-container">
      <router-view />
    </main>
    <footer>
      <AppFooter></AppFooter>
    </footer>
  </div>
</template>

<script>
import "bootstrap-icons/font/bootstrap-icons.css";
import "./assets/stylesheets/main.scss";
import NavBar from "../src/components/NavBar.vue";
import AppFooter from "../src/components/AppFooter.vue";

export default {
  name: "App",
  components: {
    NavBar,
    AppFooter,
  },
  computed:{
    isLoading:function(){
      return this.$store.getters.isLoading;
    }
  }
};
</script>
<style>
#app {
  position: relative;
}
#app .main-container {
  min-height: 70vh;
}
</style>