<template>
  <section class="section">
    <div class="container">
      <div class="clinic-list-display">
        <div class="card">
          <header class="card-header">
            <p class="card-header-title">
              {{ pagetitle }}
            </p>
          </header>
          <div class="card-content">
            <div class="content">
              <div class="list">
                <ul>
                  <div
                    v-for="clinic in clinics"
                    :key="clinic.id"
                    class="clinic"
                  >
                    <div class="list-item">
                      <li>
                        <a
                          :href="clinic.weburl"
                          target="_blank"
                          class="has-text-weight-bold"
                          rel="noopener noreferrer"
                        >
                          {{ clinic.name }}
                        </a>
                        <div>{{ clinic.address1 }} {{ clinic.address1 }}</div>
                        <div>
                          {{ clinic.city }}, {{ clinic.state }},
                          {{ clinic.zipcode }},
                        </div>
                        <div>{{ clinic.status }}</div>
                        <span class="has-text-info"
                          >({{ clinic.distance }} - Miles)</span
                        >
                      </li>
                    </div>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

</template>
<script>
export default {
  name: "Clinic-View",
  data() {
    return {
      clinics: [],
      pagetitle: "All NIH Clinics",
      clinic: null,
    };
  },
  watch: {
    "$store.getters.getClinics": {
      handler(newVal) {
        this.clinics = newVal;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style scoped>

/* left align the list */
div.card-content {
  text-align: center;
}
div.content ul {
  display: inline-flex;
  flex-direction: column;
  overflow-y:auto ;
}

div.content ul li {
  text-align: left;
}
</style>
 
