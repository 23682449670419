import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
//code splitting to lazy load components to improve application performance
const ProfileView = () => import('../views/ProfileView.vue');
const DetailsView = () => import('../views/DetailsView.vue');
const Blog = () => import('../views/blog/Blog.vue');
const BlogPost = () => import('../views/blog/BlogPost.vue');
const AboutView = () => import('../views/AboutView.vue');

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    alias: '/home'
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView
  },
  {
    path: '/detailsview',
    name: 'detailsview',
    component: DetailsView
  },
  {
    path: '/blog',
    name: 'blog',
    component: Blog,
  },
  {
    path: "/blog/post/:id/:title",
    name: "blog-post",
    component: BlogPost
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  mode: 'history',
  routes
})

export default router
