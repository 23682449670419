<template>
  <section class="section">
    <div class="container">
      <div class="card">
        <header class="card-header">
          <p class="card-header-title">
            {{ pagetitle }}
          </p>
        </header>
        <div class="card-content">
          <div class="content">
            <div class="list">
              <ul>
                <div
                  v-for="angelflight in angelflights"
                  :key="angelflight.id"
                  class="angelflight"
                >
                  <div class="list-item">
                    <li>
                      <a
                        :href="angelflight.weburl"
                        target="_blank"
                        class="has-text-weight-bold"
                        rel="noopener noreferrer"
                      >
                        {{ angelflight.name }}
                      </a>
                      <div>
                        {{ angelflight.description }}
                      </div>
                    </li>
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template> 
<script>
export default {
  data() {
    return {
      angelflights: [],
      pagetitle: "Angel Fight Organizations",
    };
  },
  mounted() {
    fetch(
      process.env.VUE_APP_API_URL +
        "/angelflights" +
        process.env.VUE_APP_API_URL_SUFFIX
    )
      .then((res) => res.json())
      .then((data) => (this.angelflights = data))
      .catch((err) => console.log(err.message));
  },
};
</script>
