import axios from "axios";
import { createStore } from "vuex";

const store = createStore({
    state: {
        clinics: [],
        filteredClinics: [],
        blogPosts: [],
        isLoading: false,
    },
    mutations: {
        setClinics(state, clinics) {
            state.clinics = clinics;
        },
        setFilteredClinics(state, filteredClinics) {
            state.filteredClinics = filteredClinics;
        },
        setBlogPosts(state, blogPosts = []) {
            state.blogPosts = blogPosts;
        },
        setIsLoading(state, isLoading = false) {
            state.isLoading = isLoading;
        }
    },
    getters: {
        getClinics(state) {
            return state.clinics;
        },
        getFilteredClinics(state) {
            return state.filteredClinics;
        },
        getBlogPosts(state) {
            return state.blogPosts;
        },
        isLoading(state) {
            return state.isLoading;
        }
    },
    actions: {
        fetchClinics({ commit }) {
            axios
                .get(
                    process.env.VUE_APP_API_URL +
                    "/clinics" +
                    process.env.VUE_APP_API_URL_SUFFIX
                )
                .then((res) => {
                    if (res.status === 200) {
                        commit("setClinics", res.data);
                    }
                })
                .catch((err) => console.log(err));
        },
        fetchBlogPosts({ commit }) {
            axios.get(process.env.VUE_APP_API_URL + "/blog_posts" + process.env.VUE_APP_API_URL_SUFFIX)
                .then(res => {
                    if (res.status === 200) {
                        commit("setBlogPosts", res.data);
                    }
                }).catch(err => {
                    console.log(err);
                })
        }
    }
});

export default store;