export function hasText(text) {
    return text && text.trim() != "" && text.trim().length > 0;
}

export function isZipcode(text) {
    return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(text);
}

export function getHoursOrMinutes(str = "", isHours = true) {
    const strLength = str.length;
    const ptIndex = 2;
    const hIndex = str.indexOf("H");
    if (isHours) {
        if (hIndex != -1) {
            return str.substring(ptIndex, hIndex) + " hr";
        }
        return "";
    } else {
        if (hIndex != -1) {
            return str.substring(hIndex + 1, strLength - 1) + " min";
        }
        return str.substring(ptIndex, strLength - 1) + " min";
    }

}