<template>
  <section class="section">
    <div class="container">
      <div class="card">
        <header class="card-header">
          <p class="card-header-title">
            {{ pagetitle }}
          </p>
        </header>
        <div class="card-content">
          <div class="content">
            <div class="list">
              <ul>
                <div
                  v-for="financialhelper in financialhelpers"
                  :key="financialhelper.id"
                  class="financialhelper"
                >
                  <div class="list-item">
                    <li>
                      <a
                        :href="financialhelper.weburl"
                        target="_blank"
                        class="has-text-weight-bold"
                        rel="noopener noreferrer"
                      >
                        {{ financialhelper.name }}
                      </a>
                      <div v-if="financialhelper.address1 !== ''">
                        {{ financialhelper.address1 }}
                        {{ financialhelper.address1 }}
                      </div>
                      <div v-if="financialhelper.city !== ''">
                        {{ financialhelper.city }}, {{ financialhelper.state }},
                        {{ financialhelper.zipcode }}
                      </div>
                      <!-- <span class="has-text-info">({{financialhelper.distance}} miles)</span> -->
                    </li>
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template> 
<script>
export default {
  name: "Financial-View",
  data() {
    return {
      financialhelpers: [],
      pagetitle: "Financial Help",
    };
  },
  mounted() {
    fetch(
      process.env.VUE_APP_API_URL +
        "/financialhelpers" +
        process.env.VUE_APP_API_URL_SUFFIX
    )
      .then((res) => res.json())
      .then((data) => (this.financialhelpers = data))
      .catch((err) => console.log(err.message));
  },
};
</script>
 